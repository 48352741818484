var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-info"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header_top"},[_c('img',{attrs:{"src":require("../../assets/left.png"),"alt":""},on:{"click":_vm.goBack}}),_c('span',{staticClass:"center"},[_vm._v("合同管理")]),_c('img',{attrs:{"src":require("../../assets/right.png"),"alt":""}}),_c('span',{staticClass:"center"},[_vm._v("合同列表")]),_c('img',{attrs:{"src":require("../../assets/right.png"),"alt":""}}),_c('span',{staticClass:"center"},[_vm._v("合同详情")])]),_c('div',[_c('el-button',{directives:[{name:"access",rawName:"v-access"},{name:"show",rawName:"v-show",value:(_vm.detailInfo.isSign),expression:"detailInfo.isSign"}],staticClass:"top_btn",attrs:{"data-ctrl":"contractSignerUrl","type":"primary","size":"small","icon":"el-icon-edit"},on:{"click":_vm.getSignUrl}},[_vm._v("签署")]),_c('el-popconfirm',{attrs:{"confirmButtonText":"确定","cancelButtonText":"取消","icon":"el-icon-info","iconColor":"red","title":"确定删除此这条数据？"},on:{"confirm":_vm.delContract}},[_c('el-button',{directives:[{name:"access",rawName:"v-access"},{name:"show",rawName:"v-show",value:(
          _vm.detailInfo.state === '1' ||
          _vm.detailInfo.state === '2' ||
          _vm.detailInfo.state === '3' ||
          _vm.detailInfo.state === '5' ||
          _vm.detailInfo.state === '6'
        ),expression:"\n          detailInfo.state === '1' ||\n          detailInfo.state === '2' ||\n          detailInfo.state === '3' ||\n          detailInfo.state === '5' ||\n          detailInfo.state === '6'\n        "}],staticClass:"top_btn",staticStyle:{"margin-left":"10px"},attrs:{"slot":"reference","data-ctrl":"contractEdit","type":"primary","size":"small","icon":"el-icon-delete"},slot:"reference"},[_vm._v("删除")])],1)],1)]),_c('el-card',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"left_top"},[_c('div',{staticClass:"left_icon"},[_c('span'),_c('span',[_vm._v("合同基本信息")])])]),_c('div',{staticClass:"text item"},[_c('el-row',[_c('el-col',{staticClass:"text-align",attrs:{"span":12}},[_vm._v("名称："+_vm._s(_vm.detailInfo.name))]),_c('el-col',{staticClass:"text-align",attrs:{"span":12}},[_vm._v("签署截止日期："+_vm._s(_vm.detailInfo.signDeadline))]),_c('el-col',{staticClass:"text-align",attrs:{"span":12}},[_vm._v(" 签署状态： "),(
              _vm.detailInfo.state === '0' ||
              _vm.detailInfo.state === '1' ||
              _vm.detailInfo.state === '6' ||
              !_vm.detailInfo.state
            )?_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm._f("contractStateVal")(_vm.detailInfo.state)))]):_vm._e(),(_vm.detailInfo.state === '2' || _vm.detailInfo.state === '3')?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm._f("contractStateVal")(_vm.detailInfo.state))+" ")]):_vm._e(),(_vm.detailInfo.state === '4')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm._f("contractStateVal")(_vm.detailInfo.state))+" ")]):_vm._e(),(_vm.detailInfo.state === '5')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" "+_vm._s(_vm._f("contractStateVal")(_vm.detailInfo.state))+" ")]):_vm._e()],1),_c('el-col',{staticClass:"text-align",attrs:{"span":12}},[_vm._v("合同分类："+_vm._s(_vm._f("contractTypeVal")(_vm.detailInfo.type)))]),_vm._l((_vm.detailInfo.signers),function(item,index){return _c('el-col',{key:index,staticClass:"text-align",attrs:{"span":12}},[_vm._v(" "+_vm._s(_vm._f("subjectTypeVal")(index))+"："+_vm._s(item.subjectName || "未设置")+" ")])}),_vm._l((_vm.detailInfo.signers),function(item,index){return _c('el-col',{key:index + 'i',staticClass:"text-align",attrs:{"span":12}},[_vm._v(" "+_vm._s(_vm._f("subjectTypeVal")(index))+"签署人："+_vm._s(item.signerName || "未设置")+" "),_c('i',{staticClass:"iconfont",class:{
              iconclock: item.signerState === '0',
              iconsuccess: item.signerState === '2',
              iconreeor: item.signerState === '4',
            }})])}),_c('el-col',{staticClass:"text-align",attrs:{"span":12}},[_vm._v("创建人："+_vm._s(_vm.detailInfo.creatorName))])],2)],1)]),_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"left_top"},[_c('div',{staticClass:"left_icon"},[_c('span'),_c('span',[_vm._v("合同附件")])])]),_c('div',{staticClass:"card-body"},[_vm._l((_vm.fileList),function(item,i){return _c('div',{key:i},[_c('span',[_vm._v("附件"+_vm._s(i + 1)+"：")]),_c('p',{staticStyle:{"display":"inline-block","min-width":"350px"}},[_vm._v(_vm._s(item.name))]),_c('a',{staticStyle:{"color":"#409eff"},attrs:{"href":item.path,"target":"_blank"}},[_vm._v("预览")])])}),_c('div',{staticStyle:{"text-align":"center"}},[(_vm.fileList.length === 0)?_c('commonEmpty',{attrs:{"emptyMessage":'暂无合同附件'}}):_vm._e()],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }