<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">合同列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">合同详情</span>
      </div>
      <div>
        <el-button
          v-access
          data-ctrl="contractSignerUrl"
          type="primary"
          size="small"
          icon="el-icon-edit"
          v-show="detailInfo.isSign"
          @click="getSignUrl"
          class="top_btn"
        >签署</el-button>
        <el-popconfirm
          @confirm="delContract"
          confirmButtonText="确定"
          cancelButtonText="取消"
          icon="el-icon-info"
          iconColor="red"
          title="确定删除此这条数据？"
        >
          <el-button
            v-access
            data-ctrl="contractEdit"
            v-show="
            detailInfo.state === '1' ||
            detailInfo.state === '2' ||
            detailInfo.state === '3' ||
            detailInfo.state === '5' ||
            detailInfo.state === '6'
          "
            slot="reference"
            style="margin-left: 10px;"
            type="primary"
            size="small"
            class="top_btn"
            icon="el-icon-delete"
          >删除</el-button>
        </el-popconfirm>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>合同基本信息</span>
          </div>
        </div>
        <div class="text item">
          <el-row>
            <el-col :span="12" class="text-align">名称：{{ detailInfo.name }}</el-col>
            <el-col :span="12" class="text-align">签署截止日期：{{ detailInfo.signDeadline }}</el-col>
            <el-col :span="12" class="text-align">
              签署状态：
              <el-tag
                v-if="
                detailInfo.state === '0' ||
                detailInfo.state === '1' ||
                detailInfo.state === '6' ||
                !detailInfo.state
              "
                type="info"
              >{{ detailInfo.state | contractStateVal }}</el-tag>
              <el-tag v-if="detailInfo.state === '2' || detailInfo.state === '3'" type="primary">
                {{ detailInfo.state |
                contractStateVal
                }}
              </el-tag>
              <el-tag v-if="detailInfo.state === '4'" type="success">
                {{
                detailInfo.state | contractStateVal
                }}
              </el-tag>
              <el-tag v-if="detailInfo.state === '5'" type="danger">
                {{
                detailInfo.state | contractStateVal
                }}
              </el-tag>
            </el-col>
            <el-col :span="12" class="text-align">合同分类：{{ detailInfo.type | contractTypeVal }}</el-col>
            <el-col
              :span="12"
              class="text-align"
              v-for="(item, index) in detailInfo.signers"
              :key="index"
            >
              {{ index |
              subjectTypeVal
              }}：{{ item.subjectName || "未设置" }}
            </el-col>
            <el-col
              :span="12"
              class="text-align"
              v-for="(item, index) in detailInfo.signers"
              :key="index + 'i'"
            >
              {{ index | subjectTypeVal }}签署人：{{
              item.signerName || "未设置"
              }}
              <i
                class="iconfont"
                :class="{
                iconclock: item.signerState === '0',
                iconsuccess: item.signerState === '2',
                iconreeor: item.signerState === '4',
              }"
              ></i>
            </el-col>
            <!-- <el-col :span="12" class="text-align"
          >签署人账号：{{ detailInfo.signerAccounts }}</el-col
        >

        <el-col :span="12" class="text-align"
          >签署人账号：{{ detailInfo.signerNames }}</el-col
        >
        <el-col :span="12" class="text-align"
          >签署主体账号：{{ detailInfo.subjectAccounts }}</el-col
        >
        <el-col :span="12" class="text-align"
          >签署主体姓名：{{ detailInfo.subjectNames }}</el-col
        >
        <el-col :span="12" class="text-align"
          >签署主体类型：{{ detailInfo.subjectTypes }}</el-col
            >-->
            <el-col :span="12" class="text-align">创建人：{{ detailInfo.creatorName }}</el-col>
          </el-row>
        </div>
      </el-card>

      <!-- <el-card class="box-card">
        <div class="left_top" style="margin-bottom: 20px">
          <div class="left_icon"><span></span> <span>合同签署人</span></div>
        </div>
        <el-table :data="singerList" style="width: 100%">
          <el-table-column label="签署方">
            <template slot-scope="scope">
              {{ scope.$index | subjectTypeVal }}
            </template>
          </el-table-column>
          <el-table-column label="签署主体">
            <template slot-scope="scope">
              {{ scope.row.subjectName }}
            </template>
          </el-table-column>
          <el-table-column label="签署人">
            <template slot-scope="scope">
              {{ scope.row.signerName }}
            </template>
          </el-table-column>
        </el-table>
      </el-card>-->

      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>合同附件</span>
          </div>
        </div>
        <div class="card-body">
          <div v-for="(item, i) in fileList" :key="i">
            <span>附件{{ i + 1 }}：</span>
            <p style="display: inline-block; min-width: 350px">{{ item.name }}</p>
            <a style="color: #409eff" :href="item.path" target="_blank">预览</a>
          </div>
          <div style="text-align: center">
            <commonEmpty :emptyMessage="'暂无合同附件'" v-if="fileList.length === 0"></commonEmpty>
          </div>
        </div>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import { api } from '/src/api/base/index';
const contractDescribe = api('/contract')('contract.describe.json');
const ContractDel = api('/contract')('contract.del.json');
export const ContractSignUrlDescribe = api('/contract')(
  'contract.signer.url.json'
);
import { parseSigner } from './utils'
import commonEmpty from '/src/components/common-empty/common-empty';
export default {
  components: {
    commonEmpty
  },
  data () {
    return {
      code: '',
      detailInfo: {
        code: '',
        name: '',
        signDeadline: '',
        flowId: '',
        state: '',
        type: '',
        signerAccounts: '',
        signerNames: '',
        subjectAccounts: '',
        subjectNames: '',
        subjectTypes: '',
        filePaths: '',
        esignFileIds: '',
        creatorAccount: '',
        creatorName: '',
        config: ''
      },
      singerList: [],
      fileList: []
    };
  },

  async mounted () {
    this.code = this.$route.query.code;
    await this.getInfo();
  },
  methods: {
    async getInfo () {
      await contractDescribe({ code: this.code }).then(res => {
        const userIndex = res.signerAccounts.split(',').indexOf(window.localStorage.getItem('code'))
        this.detailInfo = {
          ...res,
          signers: parseSigner(res),
          isSign: userIndex > -1 && ['1', '0'].includes(res.signerStates.split(',')[userIndex]) && !(res.signerStates.split(',').includes('3') || res.signerStates.split(',').includes('4')),
        };
      });
      if (this.detailInfo.signerNames) {
        this.detailInfo.signerNames.split(',').forEach((it, i) => {
          this.singerList.push({
            signerName: this.detailInfo.signerNames.split(',')[i],
            subjectName: this.detailInfo.subjectNames.split(',')[i],
            subjectType: this.detailInfo.subjectTypes.split(',')[i]
          });
        });
      }
      if (this.detailInfo.filePaths) {
        this.detailInfo.filePaths.split(',').forEach(it => {
          this.fileList.push({
            name: it.split('/')[it.split('/').length - 1],
            path: it
          });
        });
      }
    },
    goBack () {
      window.history.go(-1);
    },
    async delContract () {
      await ContractDel({
        code: this.code
      });
      this.$message.success('内容成功删除！');
      window.history.back(-1);
    },
    async getSignUrl () {
      const url = await ContractSignUrlDescribe({
        contractCode: this.code,
        signerAccount: window.localStorage.getItem('code')
      });
      window.location.href = url;
    }
  }
};
</script>

<style lang="scss">
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}

.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}

.showImgDialog .imgBox {
  display: flex;
}

.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}

.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}

.card-title {
  margin-right: 8px;
}

.card-btn {
  cursor: pointer;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}

.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marginBottom {
  margin-bottom: 12px;
}

.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}

.aType {
  color: #659fe5;
  cursor: pointer;
}

.tooltipClas {
  width: 250px;
}

.basic-info {
  .warning {
    color: #e6a23c;
  }

  .success {
    color: #67c23a;
  }

  .danger {
    color: #f56c6c;
  }

  .info {
    color: #409eff;
  }
}

.defaultTips {
  display: inline-block;
  margin: 0 8px;

  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}

.card-body {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
  font-size: 14px;
  color: #303133;
}
</style>
